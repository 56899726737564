@charset "UTF-8";

.home-banner {
  height: 8.05rem;
}

.home-banner-swiper {
  height: 100%;
}

.home-banner-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.home-banner-contain {
  position: absolute;
  top: 50%;
  left: 0;
  padding: 0 20%;
  transform: translateY(-50%);
}

.home-banner-subtitle {
  padding: 6px .3rem;
  margin-bottom: .18rem;
  border: 2px solid #fff;
  border-radius: .3rem;
  font-size: .27rem;
  line-height: 1;
  color: #fff;
}

.home-banner-desc {
  margin-top: .18rem;
  font-size: .2rem;
  color: rgba(255, 255, 255, .5);
}

.home-banner-btn {
  margin-top: .3rem;
}

.home-banner-btn .btn {
  width: 1.2rem;
  height: .42rem;
  border: 1px solid rgba(255, 255, 255, .4);
  border-radius: .32rem;
  font-size: .16rem;
  color: rgba(255, 255, 255, .8);
}

.home-banner-btn .btn:hover {
  background: #054728;
  border-color: #054728;
  color: #fff;
}

.home-banner .swiper-pagination {
  bottom: 5%;
}

.walk-inti-toshiba {
  position: relative;
  height: 6.94rem;
  margin-top: .25rem;
}

.walk-inti-toshiba .background {
  width: 100%;
  height: 100%;
}

.walk-inti-toshiba .background-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.walk-inti-contain {
  position: absolute;
  top: 32%;
  left: 0;
  width: 100%;
  color: #fff;
}

.walk-inti-title {
  font-size: .34rem;
}

.walk-inti-desc {
  margin-top: .15rem;
  font-size: .16rem;
  text-align: center;
}

.walk-inti-bottom {
  font-size: .16rem;
  margin-top: .5rem;
}

.walk-inti-bottom .btn {
  margin-left: .32rem;
  color: #fff;
  opacity: .8;
  cursor: pointer;
}

.walk-inti-bottom .btn:first-child {
  margin-left: 0;
}

.walk-inti-bottom .icon {
  margin-left: 8px;
  font-size: .28rem;
  line-height: 1;
  color: #fff;
}

.walk-inti-bottom .btn:hover {
  opacity: 1;
}


.product-series {
  padding: .8rem 0;
}

.product-series .item {
  position: relative;
  margin-top: .32rem;
  width: calc(50% - .12rem);
}

.product-series .item:nth-child(even) {
  margin-left: .24rem;
}

.product-series .image-wrap {
  position: relative;
  width: 100%;
}

.product-series .image-wrap .icon {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: .48rem;
  color: #fff;
  transform: translate(-50%, -50%) scale(0);
}

.product-series .image-box {
  position: relative;
  height: 0;
  padding-bottom: 56.3%;
  overflow: hidden;
}

.product-series .image {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  object-fit: cover;
}

.product-series .text-wrap {
  padding: .32rem;
  border-bottom: 6px solid #004d29;
  background: #12161c;
}

.product-series .text-wrap::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 6px;
  background: #fff;
  transition: all .5s cubic-bezier(.99, .01, 1, .45);
}

.product-series .title {
  font-size: .26rem;
  color: #fff;
}

.product-series .text {
  text-align: center;
  font-size: .16rem;
  color: rgba(255, 255, 255, .4);
}

.product-series .item:hover .image {
  transform: translate(-50%, -50%) scale(1.1);
}

.product-series .item:hover .text-wrap::after {
  width: 100%;
}

.product-series .item:hover .icon {
  transform: translate(-50%, -50%) scale(1);
}

.choose-toshiba {
  padding: .8rem 0 .94rem;
  background-image: url(/src/images/home-select-3.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}

.choose-toshiba .swiper-container {
  margin-top: .66rem;
}


.choose-toshiba .image-wrap {
  position: relative;
  width: 100%;
}

.choose-toshiba .image-wrap .icon {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: .49rem;
  color: #fff;
  transform: translate(-50%, -50%) scale(0);
  cursor: pointer;
}

.choose-toshiba .image-wrap::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1.92rem;
  background-color: rgba(0, 0, 0, .5);
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
}

.choose-toshiba .image-box {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.2%;
  overflow: hidden;
}

.choose-toshiba .image {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: translate(-50%, -50%);
}

.choose-toshiba .text-wrap {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 83%;
  padding: .36rem;
  background-color: rgba(0, 0, 0, .85);
}

.choose-toshiba .text-wrap .icon {
  width: .78rem;
  height: .78rem;
  margin-right: .2rem;
}

.choose-toshiba .text-wrap .title {
  font-size: .26rem;
  line-height: 1;
  color: #fff;
}

.choose-toshiba .text-wrap .text {
  margin-top: .16rem;
  font-size: .16rem;
  color: rgba(255, 255, 255, .4);
}

.choose-toshiba .swiper-slide {
  transform: scale(.5);
  padding-bottom: 4.4%;
  overflow: hidden;
}

.choose-toshiba .swiper-slide .text-wrap {
  bottom: -100%;
  opacity: 0;
}

.choose-toshiba .swiper-slide.swiper-slide-active {
  transform: scale(1);
}

.choose-toshiba .swiper-slide.swiper-slide-active .text-wrap {
  bottom: 0;
  opacity: 1;
}

.choose-toshiba .swiper-slide.swiper-slide-active .image-wrap:hover .image {
  transform: translate(-50%, -50%) scale(1.1);
}

.choose-toshiba .swiper-slide.swiper-slide-active .image-wrap:hover .icon {
  transform: translate(-50%, -50%) scale(1);
}

.choose-toshiba .swiper-pagination {
  width: auto;
  bottom: 19%;
  left: 68%;
}

.choose-toshiba .swiper-button-next,
.choose-toshiba .swiper-button-prev {
  top: auto;
  bottom: 4%;
  width: .4rem;
  height: .36rem;
  margin-top: 0;
}

.choose-toshiba .swiper-button-next::after,
.choose-toshiba .swiper-button-prev::after {
  display: none;
}

.choose-toshiba .swiper-button-next {
  right: 28.2%;
}

.choose-toshiba .swiper-button-prev {
  left: auto;
  right: 31%;
}

.choose-toshiba .swiper-button .icon .cls-1 {
  fill: #060708;
}

.choose-toshiba .swiper-button .icon:hover .cls-1 {
  fill: #004D29;
}

.news-events {
  padding: .6rem 0;
}

.news-events .left-swiper,
.news-events .right-swiper {
  width: 50%;
}

.news-events .image-wrap {
  position: relative;
  width: 100%;
}

.news-events .image-box {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.2%;
  overflow: hidden;
}

.news-events .right-swiper .image-box::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .8);
}

.news-events .image {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: translate(-50%, -50%);
}

.news-events .right-swiper .text-wrap {
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
}

.news-events .right-swiper .text-wrap .subtitle {
  font-size: .3rem;
  color: #fff;
}

.news-events .right-swiper .text-wrap .subtitle span {
  display: inline-block;
  margin: 0 5px;
  font-size: .3rem;
  color: #004d29;
}

.news-events .right-swiper .text-wrap .title {
  font-size: .32rem;
  color: #fff;
}

.news-events .right-swiper .text-wrap .text {
  font-size: .16rem;
  color: rgba(255, 255, 255, .8);
}

.news-events .right-swiper .swiper-button {
  width: .6rem;
  height: .56rem;
}

.news-events .right-swiper .swiper-button-prev {
  left: 10%;
}

.news-events .right-swiper .swiper-button-next {
  right: 10%;
}

.news-events .right-swiper .swiper-button::after {
  display: none;
}

.news-events .right-swiper .swiper-button .icon .cls-1 {
  fill: rgba(255, 255, 255, .2);
}

.news-events .right-swiper .swiper-button .icon:hover .cls-1 {
  fill: #004D29;
}

@media screen and (max-width: 1400px) {
  .home-banner-contain {
    padding-left: 10%;
    padding-right: 0;
  }
}

@media screen and (max-width: 1024px) {
  .home-banner-contain {
    padding-left: 5%;
    padding-right: 0;
  }

  .choose-toshiba {
    padding-bottom: 0;
  }

  .choose-toshiba-swiper {
    width: 90%;
    margin: 0 auto;
  }

  .choose-toshiba .swiper-container {
    padding-bottom: .9rem;
  }

  .choose-toshiba .swiper-slide {
    transform: scale(1);
  }

  .choose-toshiba .swiper-pagination {
    bottom: 6%;
    left: 50%;
    transform: translateX(-50%);
  }

  .choose-toshiba .swiper-button {
    top: 40%;
    width: 0.6rem;
    height: 0.56rem;
  }

  .choose-toshiba .swiper-button-prev {
    left: 5%;
  }

  .choose-toshiba .swiper-button-next {
    right: 5%;
  }
}

@media screen and (max-width: 992px) {
  .home-banner-contain {
    width: 100%;
    padding: 0 5%;
  }

  .walk-inti-contain {
    padding: 0 5%;
  }

  .product-series .item {
    width: 100%;
  }

  .product-series .item:nth-child(even) {
    margin-left: 0;
  }

  .choose-toshiba .text-wrap {
    position: static;
    width: 100%;
  }

  .news-events {
    flex-direction: column;
  }

  .news-events .left-swiper,
  .news-events .right-swiper {
    width: 100%;
  }
}